<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">{{ adminTitle }}</div>
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="0px" class="ms-content">
        <el-form-item prop="username">
          <el-input
            v-model="form.name"
            placeholder="请输入用户名称"
            clearable
            @keyup.enter.native="submitForm('ruleForm')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="form.password"
            show-password
            clearable
            maxlength="20"
            show-word-limit
            @keyup.enter.native="submitForm('ruleForm')"
          ></el-input>
        </el-form-item>
        <el-form-item label prop="code">
          <div class="disflex flexdr">
            <el-input
              placeholder="请输入验证码"
              v-model="form.code"
              clearable
              @keyup.enter.native="submitForm('ruleForm')"
            ></el-input>
            <el-image @click="refreshCode" :src="codeImage" alt class="codeImage">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>
        </el-form-item>
        <div class="login-btn">
          <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import LoginAjax from "@/utils/https/modules/Login.request.js";
import { generateString, setSession } from "@/utils/utils.js";

export default {
  data() {
    return {
      adminTitle: "优想用户后台",
      form: {
        name: "",
        password: "",
        code: ""
      },
      rules: {
        name: [{ required: true, message: "请输入用户名称", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }]
      },
      codeImage: "",
      codeKey: generateString("xxxxxxxxxxx")
    };
  },
  methods: {
    // 刷新验证码
    async refreshCode() {
      try {
        this.codeImage = "";
        this.codeKey = generateString("xxxxxxxxxx");
        this.codeImage = await LoginAjax.getCodeImage(this.codeKey);
      } catch (error) {
        this.$message.error(error);
      }
    },

    // 登录
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          try {
            const { data, msg } = await LoginAjax.login({
              key: this.codeKey,
              ...this.form
            });
            setSession("yxtoken", data.token);
            setSession("admindetail", data);
            this.$message.success(msg);
            // 接口返回是否某活动管理员，用于判断是否管理员
            var isSuperAdmin = (data.roleId==1 ? true : false);
            if(isSuperAdmin){
              this.$router.push("/activity");
              setSession("issuperadmin", true);
            }else{
              this.$router.push(`/activity/guide/${data.actId}`);
              setSession("issuperadmin", false);
            }
          } catch (error) {
            this.$message.error(error || "请按要求填写信息");
            this.refreshCode();
            this.form.code = "";
          }
        } else {
          return false;
        }
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      console.log(process.env);
      this.refreshCode();
    });
  }
};
</script>

<style lang="scss">
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(http://vzlive1688.vzan.com/sales/static/img/login-bg.f25d66dd.jpg);
  background-position: center 0;
  background-size: cover;
  .ms-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 53px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(38, 38, 38, 1);
    border-bottom: 1px solid rgba(245, 246, 247, 1);
  }
  .ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 390px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(243, 243, 243, 1);
    border-radius: 10px;
    overflow: hidden;
  }
  .ms-content {
    padding: 24px;
    .el-form-item__content {
      line-height: 50px;
      .el-input__inner {
        height: 50px;
        line-height: 50px;
      }
    }
  }
  .login-btn {
    text-align: center;
    button {
      width: 100%;
      height: 50px;
    }
  }
  .codeImage {
    width: 100%;
    margin-left: 10px;
    .image-slot {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .el-image__inner {
      width: 100%;
      height: auto;
    }
  }
  .put-on-record {
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
  }
}
</style>
